import { combineReducers } from 'redux'
import auth from './auth.reducer'
import message from './message.reducer'
import callhistory from './callhistory.reducer'
import voicemails from './voicemails.reducer'
import devicereducer from './devices.reducer';
import notification from './notification.reducer';
const rootReducers = combineReducers({
  auth: auth,
  message: message,
  callhistory: callhistory,
  voicemails,
  devicereducer:devicereducer,
  notification:notification
})

export default rootReducers
