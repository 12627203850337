import React from 'react';
import './Faxes.css';
export class NewFaxes extends React.Component {
	render() {
		
		return (
			<div id="new-fax" className="common-box">
				<span className="total-fax-content">
					<svg className="fax-icon">
						<use href="telicon-2.2.1.svg#device-fax" />
					</svg>
				</span>
				<span className="text text-right">
					<div className="text-count">{this.props.allfaxescount}</div>
					<div className="text-value">
						{'Total Faxes' }
					</div>
				</span>
			</div>
		);
	}
}
