import React from 'react'
import '../Home/Home.css'

const Sidebar = props => {
  const {
    history,
    changeSidebar,
    toogleSidebar,
    contactUsModal,
	 userKazooPanelModal,
    changeSetNumberModal
   
  } = props

  const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('account_id')
    localStorage.removeItem('user_id');
    localStorage.removeItem('menuNumber');    
    history.push('/')
  }
  const reload = () => {
    window.location.reload(true)
  }

  const [menuNumber, setMenuNumber] = React.useState(0);

  const changeNavActive = (index) => {
    console.log(index,'index');
    setMenuNumber(index);
    localStorage.setItem("menuNumber", index);
  }


  React.useEffect(() => {
    var menus = document.getElementsByClassName("side-nav");
    for(let i=0; i<menus.length; i++) {
        if(menus[i].classList.contains('active')) {
            menus[i].classList.remove('active');
        }
    }

    if(localStorage.getItem("menuNumber")) {
      menus[localStorage.getItem("menuNumber")].classList.add("active");
    }
    else {
      menus[menuNumber].classList.add("active");
    }

  }, [menuNumber])




  return (
    <nav className="navigation">
      <div className="nav-group">
        <ul id="sideBar">
          <li>
            <span className="logo"></span>
          </li>
          <li className="nav-menu">
            <span
              data-navigation-target="chats"
              onClick={changeSidebar}
              className={toogleSidebar ? 'active' : ''}
            >
              <i className="ti-menu-alt"></i>
            </span>
          </li>
          <li>
            <span className="side-nav active" onClick={() =>{ changeNavActive(0); history.push('/home')}}>
              <i className="ti-home"></i>
              <span>Dashboard</span>
            </span>
          </li>
          <li className="brackets">
            <span className="side-nav" onClick={() =>{ changeNavActive(1); history.push('/message')}}>
              <i className="ti-comment-alt"></i>
              <span>Message</span>
            </span>
          </li>
          <li>
            <span className="side-nav" onClick={() =>{ changeNavActive(2);  history.push('/callhistory')}}>
              <i className="ti-notepad"></i>
              <span>Call History</span>
            </span>
          </li>
          <li className="brackets">
            <span className="side-nav" onClick={() =>{ changeNavActive(3);  history.push('/voicemails')}}>
              <i className="ti-email"></i>
              <span>Voice mails</span>
            </span>
          </li>

          <li className="brackets">
            <span className="side-nav" onClick={() =>{ changeNavActive(4);  history.push('/devices')}}>
              <i className="ti-mobile"></i>
              <span>Devices & Numbers</span>
            </span>
          </li>
          

          <li>
            <span className="side-nav" onClick={() => { changeNavActive(5); contactUsModal()}}>
              <i className="ti-location-arrow"></i>
              <span>Feedback</span>
            </span>
          </li>
          <li>
            <span className="side-nav" onClick={() => { changeNavActive(6); changeSetNumberModal()}}>
              <i className="ti-settings"></i>
              <span>Settings</span>
            </span>
          </li>
          <li>
            <span className="side-nav" onClick={() => { changeNavActive(7); userKazooPanelModal()}}>
              <i className="ti-panel"></i>
              <span>Panel</span>
            </span>
          </li>
         
          
          <li>
            <span onClick={ reload}>
              <i className="ti-reload"></i>
              <span>Reload</span>
            </span>
          </li>
          <li onClick={ logout}>
            <span>
              <i className="ti-power-off"></i>
              <span>Log out</span>
            </span>
          </li>
        </ul>
      </div>
    </nav>
  )
}
export default Sidebar
