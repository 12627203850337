export const GET_NEW_TOKEN_SUCCESS = 'GET_NEW_TOKEN_SUCCESS'
export const FAIL_AUTH_REQUEST = 'FAIL_AUTH_REQUEST'
export const RESET_AUTH_TOKEN = 'RESET_AUTH_TOKEN'

export const GET_USER_NUMBER = 'GET_USER_NUMBER'
export const GET_USER_DATA = 'GET_USER_DATA'
export const GET_ALL_MESSAGES = 'GET_ALL_MESSAGES'
export const GET_ALL_USERS = 'GET_ALL_USERS'
export const SET_MEM_NUMBER = 'SET_MEM_NUMBER'
export const NEW_MESSAGE = 'NEW_MESSAGE'
export const GET_ALL_CALLFLOW = 'GET_ALL_CALLFLOW'

export const GET_ALL_VOICEMAILS = 'GET_ALL_VOICEMAILS'
export const GET_ALL_HOME = 'GET_ALL_HOME'
export const SMS_NOTIFICATION = 'SMS_NOTIFICATION'

export const GET_CALL_FORWARD = 'GET_CALL_FORWARD'
//notification
export const GET_ALL_NOTIFICATION = 'GET_ALL_NOTIFICATION'
export const SET_LANGUAGE = 'SET_LANGUAGE'



//  Global
export const SENDING_API_REQUEST = 'SENDING_API_REQUEST'
export const FAIL_API_REQUEST = 'FAIL_API_REQUEST'
export const DELETE_SYSTEMMESSAGE = "DELETE_SYSTEMMESSAGE"
export const SET_SYSTEMMESSAGE = "SET_SYSTEMMESSAGE"
export const GET_ALL_CALL_FLOW_ON_AN_ACCOUNT_SUCCESS = "GET_ALL_CALL_FLOW_ON_AN_ACCOUNT_SUCCESS"
export const GET_ALL_DEVICES_ON_AN_ACCOUNT_SUCCESS = 'GET_ALL_DEVICES_ON_AN_ACCOUNT_SUCCESS'
export const GET_ALL_NUMBER_ON_AN_ACCOUNT_SUCCESS = 'GET_ALL_NUMBER_ON_AN_ACCOUNT_SUCCESS'
export const GET_ALL_FAXES_ON_AN_ACCOUNT_SUCCESS = 'GET_ALL_FAXES_ON_AN_ACCOUNT_SUCCESS'